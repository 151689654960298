import React from 'react'

interface infoI {
  message: string
  styles?: string
};

const BaseInfoError = ({
  message,
  styles
}: infoI): JSX.Element => {
  const style = [
    styles,
    'errorfont'
  ].join(' ')
  return (
    <div
      className = { style }
    >
      { message }
    </div>
  )
}

export default BaseInfoError
