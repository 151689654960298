import React from 'react'

interface iconI {
  processing: boolean
}

// MEMO:
// 平常時（processing: false）はMailIconを表示するが、
// processing: trueの場合はローディングアニメーションを表示する。
export const MailIcon = ({ processing }: iconI): JSX.Element => {
  return processing
    ? (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='icon inline-block mr-2 animate-ping'>
        <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
      </svg>
      )
    : (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='icon inline-block mr-2 ml-0 mb-0.5'>
        <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
      </svg>
      )
}
