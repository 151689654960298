import React from 'react'
import type { ChangeEventHandler, FocusEventHandler } from 'react'
import TextIcons from './textIcons'
import BaseInfoError from './baseInfoError'

interface textareaI {
  id: string
  name: string
  placeholder?: string
  maxLength: number
  value: string
  rows: number
  onChange: ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  label?: string
  notices?: string
  required: boolean
  message: string
}

const FormTextarea = (props: textareaI): JSX.Element => {
  const {
    id,
    name,
    placeholder,
    maxLength,
    value,
    rows,
    onChange,
    onBlur,
    label,
    notices,
    required,
    message
  } = props

  const style: string = [
    'inputarea',
    'whitespace-pre-wrap'
  ].join(' ')
  const inputs: number = value.length

  return (
    <div>
      <TextIcons
        label = '必須'
        required = { required }
      />
      <label
        htmlFor = { id }
        className = 'labelfont'
      >
        { label }
      </label>
      <textarea
        id = { id }
        name = { name }
        className = { style }
        placeholder = { placeholder }
        maxLength = { maxLength }
        value = { value }
        rows = { rows }
        onChange = { onChange }
        onBlur = { onBlur }
      />
      <div className = 'noticefont mt-1 text-right'>
        { inputs } / <span className = 'text-xs'>{ maxLength.toLocaleString() }文字</span>
      </div>
      <p className = 'noticefont'>
        { notices }
      </p>
      <BaseInfoError
        message = { message }
      />
    </div>
  )
}

export default FormTextarea
