import React from 'react'

interface showI {
  label: string
  value: string
}

const FormShow = ({ label, value }: showI): JSX.Element => {
  const style: string = [`
    px-4 py-2 w-2/3
    font-medium
    whitespace-pre-wrap
  `].join(' ')

  return (
    <div className = 'flex bg-white'>
      <span
        className = 'w-1/3 noticefont font-normal block border rounded border-white bg-indigo-50 px-4 py-2'
      >
        { label }
      </span>
      <p
        className = { style }
      >
        { value }
      </p>
    </div>
  )
}

export default FormShow
