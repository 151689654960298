import React from 'react'
import logo from './assets/logo.svg'
import { ErrorsProvider } from './contexts/errorsContext'
import { AnswersProvider } from './contexts/answersContext'
import { UseStep, StepBar } from './contexts/stepContext'
import Pages from './layouts/pages'
import type { StepContextType } from './contexts/stepContext'

function App (): JSX.Element {
  const [pageStep]: StepContextType = UseStep()
  return (
    <div className = 'mainfont min-h-screen flex flex-col'>
      <header>
        <div className = 'h-10 m-4 flex space-x-2 items-end'>
          <img
          src = {logo}
          alt = 'logo'
          className = 'h-10 inline-block'
        />
        <span className = 'mb-1.5 text-[#392576] inline-block text-xs tracking-widest'>
          InsurTechカンパニー
        </span>
        </div>
      </header>
      <article className = 'container grow flex flex-wrap h-full'>
        <div className = 'w-full lg:w-1/4 mt-4'>
          <h1 className = 'text-2xl font-semibold tracking-wide'>
            お問い合わせ
          </h1>
          <span className = 'text-gray-600 text-sm italic tracking-wide'>
            Contact
          </span>
          <p className = 'lg:pr-10 mt-8 text-sm leading-6'>
            弊社サービスに関するご相談、お問い合わせを承っております。<br/>
            フォームに必要な情報をご記載のうえ送信してください。<br/>
            2～3営業日以内に、弊社の担当より電話またはメールにてご連絡差し上げます。
          </p>
        </div>
        <div className = 'w-full lg:w-3/4 mt-8'>
          <ErrorsProvider>
          <AnswersProvider>
            <StepBar step = { pageStep } />
            <Pages step = { pageStep }/>
          </AnswersProvider>
          </ErrorsProvider>
        </div>
      </article>
      <span className = 'blurBg'></span>
      <span className = 'blurSubBg'></span>
      <footer className='py-2 mt-10 border-t border-white'>
        <p className = 'text-indigo-950 text-xs tracking-wider text-center'>
          Copyright 2023 iChain, Inc.
        </p>
      </footer>
    </div>
  )
}

export default App
