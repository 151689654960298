import React from 'react'
import InputPage from './inputPage'
import ConfirmPage from './confirmPage'
import type { Step } from '../contexts/stepContext'

interface pagesI {
  step: Step
}

const Pages = ({ step }: pagesI): JSX.Element => {
  if (step === 1) {
    return (
      <InputPage/>
    )
  } else if (step === 2) {
    return (
      <ConfirmPage/>
    )
  } else if (step === 3) {
    return (
      <div className = 'mt-16 text-center'>
        <p className = 'font-bold text-lg'>
          送信完了しました。
        </p>
        <p className = 'mt-6'>
          お問い合わせいただきありがとうございます！
        </p>
        <a
          href="https://www.ichain.co.jp/"
          className = 'linkfont w-full block mt-20'
        >
          iChainのトップページに戻る
        </a>
      </div>
    )
  } else {
    return (
      <div className = 'mt-16 text-center'>
        <p className = 'font-bold text-lg'>
          エラーが発生しました。
        </p>
        <p className = 'mt-6'>
          大変申し訳ございません。入力内容の受付ができませんでした。<br/>
          お手数ですが、ページを更新して初めから再度やり直してください。
        </p>
        <a
          href="https://www.ichain.co.jp/"
          className = 'linkfont w-full block mt-20'
        >
          iChainのトップページに戻る
        </a>
      </div>
    )
  }
}

export default Pages
