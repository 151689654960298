import React, { useState } from 'react'
import BaseButtons from '../baseUI/baseButtons'
import { MailIcon } from '../baseUI/icons'
import { UseStep } from '../contexts/stepContext'
import { UseAnswers } from '../contexts/answersContext'
import FormShow from '../baseUI/formShow'
import type { stepAction } from '../contexts/stepContext'
import { UseErrors } from '../contexts/errorsContext'
import { submit } from '../services/apiServices'

const ConfirmPage = (): JSX.Element => {
  const [answers] = UseAnswers()
  const [, dispatch] = UseStep()
  const [, , , setErrorBox] = UseErrors()
  const labels = [
    'お名前',
    '貴社名（企業または団体など）',
    'メールアドレス',
    'お電話番号（ハイフンなし）',
    'お問い合わせ項目',
    '内容'
  ]
  const scrollTop = (): void => {
    window.scrollTo({ top: 0 })
  }
  const handleClick = (direction: stepAction): void => {
    if (dispatch !== null) {
      if (direction === 'next') {
        dispatch('next')
        scrollTop()
      } else {
        dispatch('prev')
        scrollTop()
      }
    }
  }
  const [processing, setProcessing] = useState<boolean>(false)
  return (
    <div className = 'mt-10'>
      <p className = 'noticefont text-cyan-500 text-center'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
      </svg>
        ブラウザの戻るボタンは使用しないでください。
      </p>
      <div className = 'shadow-sm mt-4'>
        {Object.values(answers).map((ans, i) =>
          <FormShow
            key = { i }
            label = { labels[i] }
            value = { ans }
          />
        )}
      </div>
      <div className = 'mt-6 flex justify-center space-x-8 lg:justify-between'>
        <BaseButtons
          onClick = { () => { handleClick('prev') } }
          label = '修正する'
          styles = 'secondarybutton'
          message = {''}
        />
        <BaseButtons
          onClick = {() => {
            submit(answers, setProcessing).then(() => {
              handleClick('next')
            }).catch((error) => {
              handleClick('prev')
              setErrorBox(true)
              console.log(error.code)
            })
          }}
          label = '送信する'
          styles = 'primarybutton'
          message = {''}
        >
          <MailIcon processing={ processing } />
        </BaseButtons>
      </div>
    </div>
  )
}

export default ConfirmPage
