import React, { createContext, useContext, useReducer, useState } from 'react'
import type { Dispatch } from 'react'
import type { answersI } from './answersContext'

export interface msgsI extends answersI {
  button: string
}
export interface msgsAction {
  type: keyof msgsI
  message: string
}
const defaultMsg: msgsI = {
  name: '',
  organization: '',
  email: '',
  phone: '',
  category: '',
  content: '',
  button: ''
}

/* Contextで提供するセット */
type ErrorsContextType = [
  msgsI, // エラーメッセージ
  Dispatch<msgsAction>, // エラーメッセージのDispach
  JSX.Element, // エラー時限定の表示
  Dispatch<boolean> // エラー時限定の表示のDispach
]
const ErrorsContext = createContext<ErrorsContextType>([
  defaultMsg,
  () => {},
  (<></>),
  () => {}
])

const msgReducer = (state: msgsI, action: msgsAction): msgsI => {
  const message = action.message
  switch (action.type) {
    case 'name' :
      return { ...state, name: message }
    case 'organization' :
      return { ...state, organization: message }
    case 'email' :
      return { ...state, email: message }
    case 'phone' :
      return { ...state, phone: message }
    case 'category' :
      return { ...state, category: message }
    case 'content' :
      return { ...state, content: message }
    case 'button' :
      return { ...state, button: message }
    default : return state
  }
}

const ErrorBox = (errorBox: boolean): JSX.Element => {
  if (errorBox) {
    return (
      <div className = 'text-center errorfont rounded-md bg-red-50 p-8 ring-1 ring-red-500'>
        <p className = 'font-bold text-lg'>
          送信エラー
        </p>
        <p className = 'mt-6'>
          大変申し訳ございません。入力内容の受付ができておりません。お手数ですが内容に誤りがないか再度確認してください。
        </p>
      </div>
    )
  } else {
    return (<></>)
  }
}

const ErrorsProvider = ({ children }: any): JSX.Element => {
  const [errorMsgs, dispatchErrorMsgs] = useReducer(msgReducer, defaultMsg)
  const [errorBox, setErrorBox] = useState<boolean>(false)
  return (
    <ErrorsContext.Provider value = {[
      errorMsgs,
      dispatchErrorMsgs,
      ErrorBox(errorBox),
      setErrorBox
    ]}>
      { children }
    </ErrorsContext.Provider>
  )
}

const UseErrors = (): ErrorsContextType => {
  return useContext(ErrorsContext)
}

export { ErrorsProvider, UseErrors }
