import React from 'react'
import type { ChangeEventHandler } from 'react'
import BaseButtons from '../baseUI/baseButtons'
import { UseStep } from '../contexts/stepContext'
import FormInputs from '../baseUI/formInput'
import FormTextarea from '../baseUI/formTextarea'
import FormSelect from '../baseUI/formSelect'
import { UseAnswers } from '../contexts/answersContext'
import { UseErrors } from '../contexts/errorsContext'
import { toPhoneNum, isNumber, allValidations, invalidMsgs } from '../baseUI/formValidation'
import type { msgsAction, msgsI } from '../contexts/errorsContext'
import type { stepAction } from '../contexts/stepContext'
import type { answersI } from '../contexts/answersContext'

const scrollTop = (): void => {
  window.scrollTo({ top: 0 })
}
function InputPage (): JSX.Element {
  const [answers, dispatchAns] = UseAnswers()
  const [, dispatchStep] = UseStep()
  const [errorMsgs, dispatchError, useErrorBox] = UseErrors()
  const clearError = (key: keyof msgsI): void => {
    if (dispatchError !== null) {
      dispatchError({ type: key, message: '' })
    }
  }
  const handleClick = (direction: stepAction): void => {
    /* 送信前バリデーション */
    const [isError, msgs]: [boolean, msgsAction[]] = allValidations(answers)
    if (dispatchError !== null) {
      dispatchError({ type: 'button', message: '' })
      msgs.forEach((msgs: msgsAction) => {
        dispatchError(msgs)
      })
    }
    if (!isError && (dispatchStep !== null)) {
      if (direction === 'next') {
        dispatchStep('next')
        scrollTop()
      } else {
        dispatchStep('prev')
        scrollTop()
      }
    }
  }
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const typeID: string = e.target.id
    let value: string = e.target.value
    if (dispatchError !== null && dispatchAns !== null) {
      switch (typeID) {
        case 'phone' :
          clearError(typeID)
          value = toPhoneNum(value)
          if (!isNumber(value)) {
            dispatchError({ type: typeID, message: invalidMsgs.number })
          }
          dispatchAns({ type: typeID, [typeID]: value })
          break
        case 'name' :
          clearError(typeID)
          dispatchAns({ type: typeID, [typeID]: value })
          break
        case 'organization' :
          clearError(typeID)
          dispatchAns({ type: typeID, [typeID]: value })
          break
        case 'email' :
          clearError(typeID)
          dispatchAns({ type: typeID, [typeID]: value })
          break
      }
      dispatchError({ type: 'button', message: '' })
    }
  }
  const handleSelect: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const typeID = 'category'
    const value = e.target.value
    if (dispatchError !== null && dispatchAns !== null) {
      clearError(typeID)
      dispatchAns({ type: typeID, category: value })
      dispatchError({ type: 'button', message: '' })
    }
  }
  const handleChangeText: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const typeID = 'content'
    const value = e.target.value
    if (dispatchError !== null && dispatchAns !== null) {
      clearError(typeID)
      dispatchAns({ type: typeID, content: value })
      dispatchError({ type: 'button', message: '' })
    }
  }

  const idList: Record<keyof answersI, keyof answersI> = {
    name: 'name',
    organization: 'organization',
    email: 'email',
    phone: 'phone',
    category: 'category',
    content: 'content'
  }
  return (
    <div className = 'mt-10'>
      { useErrorBox }
      <div>
        <FormInputs
          id = {idList.name}
          name = {idList.name}
          placeholder = '入力してください'
          type = 'text'
          autoComplete = 'name'
          maxLength = {25}
          value = {answers.name}
          onChange = {handleChange}
          label = 'お名前'
          required = {true}
          message = {errorMsgs.name} />
        <FormInputs
          id = {idList.organization}
          name = {idList.organization}
          placeholder = '入力してください'
          type = 'text'
          maxLength = {50}
          value = {answers.organization}
          onChange = {handleChange}
          label = '貴社名（企業または団体など）'
          required = {true}
          message = {errorMsgs.organization} />
        <FormInputs
          id = {idList.email}
          name = {idList.email}
          placeholder = '入力してください'
          type = 'email'
          autoComplete = 'email'
          maxLength = {255}
          value = {answers.email}
          onChange = {handleChange}
          label = 'メールアドレス'
          notices = '例：sample@example.co.jp'
          required = {true}
          message = {errorMsgs.email} />
        <FormInputs
          id = {idList.phone}
          name = {idList.phone}
          placeholder = '入力してください'
          type = 'text'
          autoComplete = 'tel'
          maxLength = {11}
          value = {answers.phone}
          onChange = {handleChange}
          label = 'お電話番号（ハイフンなし）'
          notices = '例：09011112222'
          required = {true}
          message = {errorMsgs.phone} />
        <FormSelect
          id = {idList.category}
          name = {idList.category}
          placeholder = '選択してください'
          value = {answers.category}
          options = {[
            'iChain保険ウォレット・うちの保険',
            'SaaS型保険システム（iChainBase）',
            '組込保険ソリューション',
            '協業・提携・出資',
            '採用',
            'その他'
          ]}
          onChange = {handleSelect}
          label = 'お問い合わせ項目'
          required = {true}
          message = {errorMsgs.category} />
        <FormTextarea
          id = {idList.content}
          name = {idList.content}
          placeholder = 'お問い合わせの詳細を入力してください'
          maxLength = {1000}
          value = {answers.content}
          rows = {8}
          onChange = {handleChangeText}
          label = '内容'
          required = {true}
          message = {errorMsgs.content} />
      </div>
      <div className = 'mt-8'>
        <h4 className = 'font-semibold'>
          iChain - プライバシーポリシー
        </h4>
        <p className='text-sm mt-2'>
          いただいた情報は
          <a
            href = 'https://d1lptzevgrj8vm.cloudfront.net/doc/ichain_privacy_policy.pdf'
            target = '_blank'
            rel = 'noopener noreferrer'
            className = 'linkfont'
          >
            弊社プライバシーポリシー
            <svg
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </a>
          に基づき、管理させていただきます。
        </p>
      </div>
      <div className = 'mt-6 flex justify-center lg:justify-end'>
        <BaseButtons
        onClick={() => {
          handleClick('next')
        } }
        label = '同意して進む'
        styles = 'primarybutton'
        message = {errorMsgs.button}
      />
      </div>
    </div>
  )
}

export default InputPage
