import axios from 'axios'
import type { answersI } from '../contexts/answersContext'

export const submit = async (
  answers: answersI,
  setProcessing: React.Dispatch<boolean>
): Promise<void> => {
  const baseURL = process.env.REACT_APP_MAIL_API_URL
  if (baseURL !== undefined) {
    setProcessing(true)
    await axios.post(baseURL, answers)
      .then((response) => {
        (response !== undefined) && console.info(response.data)
      })
      .catch((error) => {
        console.error('Error "', error.message, '",', error.response.data.errors)
        throw (error)
      })
      .finally(() => {
        setProcessing(false)
      })
  }
  await Promise.resolve()
}
