import React, { useReducer, createContext, useContext } from 'react'
import type { Dispatch } from 'react'

export type answersI = {
  [key in 'name' | 'organization' | 'email' | 'phone' | 'category' | 'content']: string
} & {
  name: string
  organization: string
  email: string
  phone: string
  category: string
  content: string
}
type Action =
| { type: 'name', name: answersI['name'] }
| { type: 'organization', organization: answersI['organization'] }
| { type: 'email', email: answersI['email'] }
| { type: 'phone', phone: answersI['phone'] }
| { type: 'category', category: answersI['category'] }
| { type: 'content', content: answersI['content'] }

export const defaultAnswers: answersI = {
  name: '',
  organization: '',
  email: '',
  phone: '',
  category: '',
  content: ''
}

type AnswersContextType = [answersI, Dispatch<Action>]
const AnswersContext = createContext<AnswersContextType>([defaultAnswers, () => {}])

const answersReducer = (state: answersI, action: Action): answersI => {
  switch (action.type) {
    case 'name' :
      return { ...state, name: action.name }
    case 'organization' :
      return { ...state, organization: action.organization }
    case 'email' :
      return { ...state, email: action.email }
    case 'phone' :
      return { ...state, phone: action.phone }
    case 'category' :
      return { ...state, category: action.category }
    case 'content' :
      return { ...state, content: action.content }
    default : return state
  }
}

const AnswersProvider = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const [answers, dispatch] = useReducer(answersReducer, defaultAnswers)
  return (
    <AnswersContext.Provider value = {[answers, dispatch]}>
      { children }
    </AnswersContext.Provider>
  )
}
const UseAnswers = (): AnswersContextType => {
  return useContext(AnswersContext)
}

export { AnswersProvider, UseAnswers }
