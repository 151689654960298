import React from 'react'
import type { ChangeEventHandler, FocusEventHandler } from 'react'
import TextIcons from './textIcons'
import BaseInfoError from './baseInfoError'

interface selectI {
  id: string
  name: string
  placeholder?: string
  value: string
  options: string[]
  onChange: ChangeEventHandler<HTMLSelectElement>
  onBlur?: FocusEventHandler<HTMLSelectElement>
  label?: string
  notices?: string
  required: boolean
  message: string
}

const FormSelect = (props: selectI): JSX.Element => {
  const {
    id,
    name,
    placeholder,
    value,
    options,
    onChange,
    onBlur,
    label,
    notices,
    required,
    message
  } = props
  return (
    <div>
      <TextIcons
        label = '必須'
        required = { required }
      />
      <label
        htmlFor = { id }
        className = 'labelfont'
      >
        { label }
      </label>
      <div className = 'relative'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 absolute right-4 top-2 text-gray-400 pointer-events-none">
          <path fillRule="evenodd" d="M11.47 4.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 01-1.06 1.06L12 6.31 8.78 9.53a.75.75 0 01-1.06-1.06l3.75-3.75zm-3.75 9.75a.75.75 0 011.06 0L12 17.69l3.22-3.22a.75.75 0 111.06 1.06l-3.75 3.75a.75.75 0 01-1.06 0l-3.75-3.75a.75.75 0 010-1.06z" clipRule="evenodd" />
        </svg>
        <select
          id = { id }
          name = { name }
          value = { value }
          onChange = { onChange }
          onBlur = { onBlur }
          className = 'inputarea appearance-none invalid:text-gray-400'
          required
        >
          <option value = ''>
            { placeholder }
          </option>
          {options.map((opt, i) =>
            <option
              key = { i }
              value = { opt }
            >
              { opt }
            </option>
          )}
        </select>
      </div>
      <p className = 'noticefont mt-1'>
        { notices }
      </p>
      <BaseInfoError
        message = { message }
      />
    </div>
  )
}

export default FormSelect
