import React from 'react'

interface iconI {
  label: string
  required: boolean
}

const isShow = (display: boolean): string => {
  switch (display) {
    case true :
      return 'showIcon'
    case false :
      return 'hideIcon'
  }
}

const styles: string = [
  isShow,
  `bg-cyan-500 text-white
  py-1 px-1.5 mr-2 rounded-2xl shadow-sm
  text-[10px] align-middle`
].join(' ')

const TextIcons = ({ label, required }: iconI): JSX.Element => {
  return (
    <span className={ styles }>
      { label }
    </span>
  )
}

export default TextIcons
