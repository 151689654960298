import React from 'react'
import BaseInfoError from './baseInfoError'

interface buttonI {
  onClick: () => void
  label: string
  styles: buttonType
  isDisabled?: boolean
  message: string
  children?: JSX.Element | JSX.Element[]
}
export type buttonType = 'primarybutton' | 'secondarybutton'

const BaseButtons = ({
  onClick,
  label,
  styles,
  isDisabled,
  message,
  children = <></>
}: buttonI): JSX.Element => {
  return (
    <div
      className = 'mt-6'
    >
      <button
        onClick = { onClick }
        className = { styles }
        disabled = { isDisabled }
      >
        { children }
        { label }
      </button>
      <BaseInfoError
        message = { message }
      />
    </div>
  )
}

export default BaseButtons
