import React, { useReducer, createContext, useContext } from 'react'

export type Step = number
export type stepAction = 'prev' | 'next' | 'error'

const initialStep: Step = 1

export type StepContextType = [Step, React.Dispatch<stepAction>]
const StepContext = createContext<StepContextType>([initialStep, () => {}])

const stepReducer = (step: Step, action: stepAction): Step => {
  switch (action) {
    case 'prev':
      return step - 1
    case 'next':
      return step + 1
    case 'error':
      return 0
    default :
      return step
  }
}

const StepProvider = ({ children }: any): JSX.Element => {
  const [step, dispatch] = useReducer(stepReducer, initialStep)
  return (
    <>
      <StepContext.Provider value = {[step, dispatch]}>
        { children }
      </StepContext.Provider>
    </>
  )
}

const icons: Record<string, JSX.Element> = {
  edit: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="iconM">
      <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
    </svg>
  ),
  check: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="iconM">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
    </svg>
  ),
  send: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="iconM">
      <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
    </svg>
  ),
  done: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="iconM">
      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </svg>
  ),
  arrow: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
      <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
    </svg>
  ),
  error: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon">
      <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
    </svg>
  )
}

const StepBar = ({ step }: { step: number }): JSX.Element => {
  const stepIcons = (page: number, icon: JSX.Element, text: string): JSX.Element => {
    if (step > page) {
      return (
        <>
          { icons.done }
          <p>{ text }</p>
        </>
      )
    } else if (step === page) {
      return (
        <>
          { icon }
          <p className = 'font-bold underline underline-offset-2 text-indigo-950'>{ text }</p>
        </>
      )
    } else if (step === 0) {
      return (
        <>
          { icons.error }
          <p>{ text }</p>
        </>
      )
    } else {
      return (
        <>
          <div className = 'w-full'>●</div>
          <p>{ text }</p>
        </>
      )
    }
  }
  return (
    <>
      <div className = 'flex justify-center text-center space-x-6 mt-2 text-[10px] cursor-default text-indigo-900'>
        <div>
          <p className = 'pb-4 text-sm'>STEP 1</p>
          { stepIcons(1, icons.edit, '入力') }
        </div>
        <span>{ icons.arrow }</span>
        <div>
         <p className = 'pb-4 text-sm'>STEP 2</p>
          { stepIcons(2, icons.check, '確認') }
        </div>
        <span>{ icons.arrow }</span>
        <div>
         <p className = 'pb-4 text-sm'>STEP 3</p>
          { stepIcons(3, icons.send, '完了') }
        </div>
      </div>
    </>
  )
}

function UseStep (): StepContextType {
  return useContext(StepContext)
}

export { StepProvider, UseStep, StepBar }
