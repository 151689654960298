import React from 'react'
import type { ChangeEventHandler, FocusEventHandler } from 'react'
import TextIcons from './textIcons'
import BaseInfoError from './baseInfoError'

export interface inputI {
  id: string
  name: string
  placeholder?: string
  type: inputType
  autoComplete?: string
  maxLength: number
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  label?: string
  notices?: string
  required: boolean
  message: string
}

export type inputType = 'text' | 'email' | 'tel'

const FormInputs = (props: inputI): JSX.Element => {
  const {
    id,
    name,
    placeholder,
    type,
    autoComplete,
    maxLength,
    value,
    onChange,
    onBlur,
    label,
    notices,
    required,
    message
  } = props
  return (
    <div>
      <TextIcons
        label = '必須'
        required = { required }
      />
      <label
        htmlFor = { id }
        className = 'labelfont'
      >
        { label }
      </label>
      <input
        id = { id }
        name = { name }
        className = 'inputarea'
        placeholder = { placeholder }
        type = { type }
        autoComplete = { autoComplete }
        maxLength = { maxLength }
        value = { value }
        onChange = { onChange }
        onBlur = { onBlur }
      />
      <p className = 'noticefont mt-1'>
        { notices }
      </p>
      <BaseInfoError
        message = { message }
      />
    </div>
  )
}

export default FormInputs
